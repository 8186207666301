import React from 'react';

import Button from 'Components/Button';

import './_projectCard.scss';

interface ProjectCardProps {
  image: string;
  company: string;
  title: string;
  onClick?: () => void;
}

export const ProjectCard = ({ image, company, title, onClick }: ProjectCardProps): JSX.Element => {
  return (
    <div className="ProjectCard -head" onClick={onClick}>
      <div className="ProjectCard -header" style={{ backgroundImage: `url(${image})` }}>
        <Button type="button" className="Button-brand">
          View Project
        </Button>
      </div>
      <div className="ProjectCard -body">
        <h3 className="ProjectCard -body-company">{company}</h3>
        <p className="ProjectCard -body-title">{title}</p>
      </div>
    </div>
  );
};

export default ProjectCard;
