import axios from 'Shared/axios';

import * as types from 'Store/types/project';
import { addAlert } from '.';
import { IAction, IProject, IGallery } from 'Types/general';
import { RootState } from 'Store';

export const fetchProjects =
  () =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<IProject[]> => {
    let data;
    try {
      dispatch({ type: types.PROJECT_INIT });

      const projects = await axios.get(`/project/v1/projects`, {
        headers: { authorization: `Bearer ${getState().auth.token}` },
      });
      data = projects.data.data;

      dispatch({ type: types.FETCH_PROJECTS, payload: data });
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.PROJECT_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

export const createProject =
  (e: FormData) =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<IProject> => {
    let data;
    try {
      dispatch({ type: types.PROJECT_INIT });

      const createProject = await axios.post(`/project/v1/`, e, {
        headers: {
          'content-type': 'multipart/form-data',
          authorization: `Bearer ${getState().auth.token}`,
        },
      });
      data = createProject.data.data;
      //@ts-expect-error mismatch
      dispatch(addAlert('success', createProject.data.message));

      dispatch({ type: types.CREATE_PROJECT, payload: data });
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.PROJECT_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

export const editProject =
  (projectId: string, params: FormData) =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<IProject> => {
    let data;
    try {
      dispatch({ type: types.PROJECT_INIT });

      const updateProject = await axios.put(`/project/v1/project/${projectId}`, params, {
        headers: {
          'content-type': 'multipart/form-data',
          authorization: `Bearer ${getState().auth.token}`,
        },
      });
      data = updateProject.data.data;
      //@ts-expect-error mismatch
      dispatch(addAlert('info', updateProject.data.message));

      //@ts-expect-error mismatch
      await dispatch(fetchProjects());

      dispatch({ type: types.EDIT_PROJECT, payload: data });
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.PROJECT_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

export const deleteProject =
  (projectId: string) =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<IProject> => {
    let data;
    try {
      dispatch({ type: types.PROJECT_INIT });

      const deleteProject = await axios.delete(`/project/v1/project/${projectId}`, {
        headers: { authorization: `Bearer ${getState().auth.token}` },
      });
      data = deleteProject.data.data;
      //@ts-expect-error mismatch
      dispatch(addAlert('success', deleteProject.data.message));

      //@ts-expect-error mismatch
      dispatch(fetchProjects());
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.PROJECT_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

export const deleteGallery =
  (projectId: string, galleryId: string) =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<IGallery> => {
    let data;
    try {
      dispatch({ type: types.PROJECT_INIT });

      const deleteGallery = await axios.delete(
        `/project/v1/project/${projectId}/gallery/${galleryId}`,
        {
          headers: { authorization: `Bearer ${getState().auth.token}` },
        }
      );
      data = deleteGallery.data.data;
      //@ts-expect-error mismatch
      dispatch(addAlert('success', deleteGallery.data.message));

      //@ts-expect-error mismatch
      await dispatch(fetchProjects());

      dispatch({ type: types.DELETE_GALLERY, payload: projectId });
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.PROJECT_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

export const selectProject =
  (project: IProject) =>
  (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): void => {
    dispatch({ type: types.SELECT_PROJECT, payload: project });
  };
