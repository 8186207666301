import axios from 'Shared/axios';

import * as types from 'Store/types/user';
import { addAlert } from '.';
import { IAction, IUser } from 'Types/general';
import { RootState } from 'Store';

export const fetchUsers =
  () =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<{ user: IUser; token: string }> => {
    let data;
    try {
      dispatch({ type: types.USER_INIT });

      const users = await axios.get(`/user/v1/users`, {
        headers: { authorization: `Bearer ${getState().auth.token}` },
      });
      data = users.data.data;

      dispatch({ type: types.FETCH_USERS, payload: data });
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.USER_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

export const createUser =
  (name: { firstName: string; lastName: string }, email: string, pin: string) =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<IUser> => {
    let data;
    try {
      dispatch({ type: types.USER_INIT });

      const createUser = await axios.post(
        `/user/v1/`,
        { name, email, pin },
        { headers: { authorization: `Bearer ${getState().auth.token}` } }
      );
      data = createUser.data.data;
      //@ts-expect-error mismatch
      dispatch(addAlert('success', createUser.data.message));

      dispatch({ type: types.CREATE_USER, payload: data.user });
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.USER_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

export const updateUser =
  (userId: string, userType: string) =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<IUser> => {
    let data;
    try {
      dispatch({ type: types.USER_INIT });

      const updateUser = await axios.put(
        `/user/v1/user/${userId}`,
        { userType },
        { headers: { authorization: `Bearer ${getState().auth.token}` } }
      );
      data = updateUser.data.data;
      //@ts-expect-error mismatch
      dispatch(addAlert('info', updateUser.data.message));

      //@ts-expect-error mismatch
      dispatch(fetchUsers());
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.USER_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

export const deleteUser =
  (userId: string) =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<IUser> => {
    let data;
    try {
      dispatch({ type: types.USER_INIT });

      const deleteUser = await axios.delete(`/user/v1/user/${userId}`, {
        headers: { authorization: `Bearer ${getState().auth.token}` },
      });
      data = deleteUser.data.data;
      //@ts-expect-error mismatch
      dispatch(addAlert('success', deleteUser.data.message));

      //@ts-expect-error mismatch
      dispatch(fetchUsers());
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.USER_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };
