import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { FilePondInitialFile } from 'filepond';
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-image-validate-size';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { createProject } from 'Store/actions';
import { RootState } from 'Store';
import { checkValidity } from 'Shared/validations';
import { IForm } from 'Types/general';

import Modal from 'Components/Modal';
import Input from 'Components/Input';
import Label from 'Components/Label';
import Button from 'Components/Button';
import Close from 'Components/Icons/General/Close';

// Register the plugins
registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview
);

import './_createProject.scss';

interface ProjectProps {
  isOpen: boolean;
  onClose?: () => void;
}

export const CreateProject = ({ isOpen, onClose }: ProjectProps): JSX.Element => {
  const dispatch = useDispatch();
  const [company, setCompany] = useState<IForm>({
    value: '',
    valid: false,
    focused: false,
    messageClassName: 'none',
  });
  const [description, setDescription] = useState<IForm>({
    value: '',
    valid: false,
    focused: false,
    messageClassName: 'none',
  });
  const [caseStudy, setCaseStudy] = useState<EditorState>(EditorState.createEmpty());
  const [solution, setSolution] = useState<EditorState>(EditorState.createEmpty());
  const [files, setFiles] = useState<(string | FilePondInitialFile | Blob)[]>([]);

  const { loading } = useSelector(({ project }: RootState) => project);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (
      !company.valid ||
      !description.valid ||
      files.length === 0 ||
      company.value === '' ||
      description.value === '' ||
      caseStudy.isEmpty ||
      solution.isEmpty
    ) {
      toast.error('Invalid Form Submission!');
    } else {
      const formData = new FormData();

      formData.append('name', company.value);
      formData.append('description', description.value);
      formData.append('background', draftToHtml(convertToRaw(caseStudy.getCurrentContent())));
      formData.append('solutions', draftToHtml(convertToRaw(solution.getCurrentContent())));
      formData.append('draft', 'false');

      //formData.append(`gallery`, files);
      for (let i = 0; i < files.length; i++) {
        //@ts-expect-error mismatch
        formData.append('gallery', files[i].file);
      }

      await dispatch(createProject(formData));

      if (onClose) onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} className="CreateProject -head">
      <div className="CreateProject -body">
        <div className="CreateProject -close">
          <Close onClick={onClose} />
        </div>
        <h2 className="CreateProject -title">Create Project</h2>
        <small>Create a project on the bodds website</small>

        <form className="CreateProject -form" onSubmit={onSubmit}>
          <div className="CreateProject -form-item-input">
            <Input
              label="Company Name"
              attributes={{
                type: 'text',
                name: 'company',
                required: true,
                theme: 'default',
                value: company.value,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange: (event: any) =>
                  setCompany({
                    ...company,
                    value: event.target.value,
                    valid: checkValidity(event.target.value, {
                      required: true,
                    }),
                    messageClassName:
                      !checkValidity(event.target.value, {
                        required: true,
                      }) && event.target.value !== ''
                        ? 'input__message--error'
                        : 'none',
                  }),
                onFocus: () =>
                  setCompany({
                    ...company,
                    focused: true,
                  }),
                onBlur: () =>
                  setCompany({
                    ...company,
                    focused: false,
                  }),
              }}
              hasError={!company.valid}
              focused={company.focused}
              message={
                !company.valid && !company.focused && company.value !== ''
                  ? 'The name of the company is required!'
                  : ''
              }
              messageClassName={company.messageClassName}
            />
          </div>
          <div className="CreateProject -form-item-input">
            <Input
              label="Short Description"
              attributes={{
                type: 'text',
                name: 'description',
                required: true,
                theme: 'default',
                value: description.value,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange: (event: any) =>
                  setDescription({
                    ...description,
                    value: event.target.value,
                    valid: checkValidity(event.target.value, {
                      required: true,
                      maxLength: 200,
                    }),
                    messageClassName:
                      !checkValidity(event.target.value, {
                        required: true,
                        maxLength: 200,
                      }) && event.target.value !== ''
                        ? 'input__message--error'
                        : 'none',
                  }),
                onFocus: () =>
                  setDescription({
                    ...description,
                    focused: true,
                  }),
                onBlur: () =>
                  setDescription({
                    ...description,
                    focused: false,
                  }),
              }}
              hasError={!description.valid}
              focused={description.focused}
              message={
                !description.valid && !description.focused && description.value !== ''
                  ? 'The description is required and must not be more than 200 characters!'
                  : ''
              }
              messageClassName={description.messageClassName}
            />
          </div>

          <div className="CreateProject -form-item">
            <Label>
              Case Study
              <br />
              Background*
            </Label>

            <Editor
              editorState={caseStudy}
              toolbarClassName="toolbarClassName"
              wrapperClassName="CreateProject -wrapper"
              editorClassName="CreateProject -editor"
              onEditorStateChange={setCaseStudy}
            />
          </div>

          <div className="CreateProject -form-item">
            <Label>Our Solution*</Label>

            <Editor
              editorState={solution}
              toolbarClassName="toolbarClassName"
              wrapperClassName="CreateProject -wrapper"
              editorClassName="CreateProject -editor"
              onEditorStateChange={setSolution}
            />
          </div>

          <div className="CreateProject -form-item">
            <Label>Gallery*</Label>

            <FilePond
              files={files}
              allowFileTypeValidation={true}
              acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'image/gif']}
              //@ts-expect-error type mismatch
              onupdatefiles={setFiles}
              allowMultiple={true}
              maxFiles={7}
              maxFileSize="30MB"
              name="gallery"
            />
          </div>

          <div className="CreateProject -form-btn">
            <Button className="Button-brand">
              {loading ? 'Creating Project...' : 'Create Project'}
            </Button>

            <Button type="button" className="Button-cancel" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateProject;
