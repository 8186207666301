import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { removeAlert, fetchProjects, selectProject } from 'Store/actions';
import { RootState } from 'Store';
import { IProject } from 'Types/general';
import { baseImageUrl } from 'Shared/config';
import * as urls from 'Shared/routes.json';

import Dashboard from 'Layouts/Dashboard';
import Button from 'Components/Button';
import ProjectCard from 'Components/ProjectCard';
import CreateProject from 'Components/Modals/CreateProject';
import ProjectIcon from 'Components/Icons/Dashboard/Project';

import './_projects.scss';

export const Projects = (): JSX.Element => {
  const [isAddProjectOpen, toggleAddProject] = useState<boolean>(false);
  const { loading, projects } = useSelector(({ project }: RootState) => project);
  const { type, message } = useSelector(({ alert }: RootState) => alert);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeAlert());
    if (type) toast[type](message);
  }, [type]);

  useEffect(() => {
    dispatch(fetchProjects());
  }, []);

  const onSelectProject = (project: IProject) => {
    dispatch(selectProject(project));
    history.push(urls.Project);
  };

  return (
    <Dashboard>
      <>
        <ToastContainer />
        <div className="Projects -head">
          {!loading && projects && projects.length === 0 && (
            <div className="Projects -empty">
              <div className="Projects -empty-icon">
                <ProjectIcon />
              </div>
              <div className="Projects -empty-text">There are currently no projects to display</div>
              <div className="Projects -empty-cta">
                <Button
                  type="button"
                  className="Button-brand"
                  onClick={() => toggleAddProject(!isAddProjectOpen)}>
                  Add New Project
                </Button>
              </div>
            </div>
          )}

          {!loading && projects && projects.length > 0 && (
            <div className="Projects -body">
              <div className="Projects -cta">
                <Button
                  type="button"
                  className="Button-brand"
                  onClick={() => toggleAddProject(!isAddProjectOpen)}>
                  Add New Project
                </Button>
              </div>

              <div className="Projects -content">
                {projects.map((project, idx: number) => (
                  <ProjectCard
                    key={idx}
                    // eslint-disable-next-line @typescript-eslint/no-var-requires
                    image={
                      project.gallery.length > 0 ? baseImageUrl + project.gallery[0].image : ''
                    }
                    company={project.name}
                    title={project.description}
                    onClick={() => onSelectProject(project)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        <CreateProject
          isOpen={isAddProjectOpen}
          onClose={() => toggleAddProject(!isAddProjectOpen)}
        />
      </>
    </Dashboard>
  );
};

export default Projects;
