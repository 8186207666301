import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
//import storage from 'redux-persist/lib/storage';
import { environment } from 'Shared/config';

//reducers
import alertReducer from './reducers/alert';
import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import projectReducer from './reducers/project';

//redux dev tools
const composeEnhancers =
  (environment === 'development'
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose)
    : null) || compose;

//combining all reducers
const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  user: userReducer,
  project: projectReducer,
});

const persistConfig = {
  key: 'BoddsAdminApp',
  storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//creating a redux store
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
