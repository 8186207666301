import axios from 'Shared/axios';

import * as types from 'Store/types/auth';
import { addAlert } from '.';
import { IAction, IUser } from 'Types/general';
import { RootState } from 'Store';

export const login =
  (email: string, pin: string) =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<{ user: IUser; token: string }> => {
    let data;
    try {
      dispatch({ type: types.AUTH_INIT });

      const login = await axios.post(`/user/v1/login`, { email, pin });
      data = login.data.data;
      //@ts-expect-error mismatch
      dispatch(addAlert('success', login.data.message));
      window.localStorage.setItem('token', data.token);

      dispatch({ type: types.LOGIN, payload: { user: data.user, token: data.token } });
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.AUTH_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data && errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

export const logout =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch: (e: IAction) => void, getState: () => RootState): Promise<void> => {
    dispatch({ type: types.LOGOUT });
  };
