import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { createUser } from 'Store/actions';
import { RootState } from 'Store';
import { checkValidity } from 'Shared/validations';
import { IForm } from 'Types/general';

import Modal from 'Components/Modal';
import Input from 'Components/Input';
import Button from 'Components/Button';
import Close from 'Components/Icons/General/Close';

import './_createUser.scss';

interface CreateUserProps {
  isOpen: boolean;
  onClose?: () => void;
}

export const CreateUser = ({ isOpen, onClose }: CreateUserProps): JSX.Element => {
  const dispatch = useDispatch();
  const { loading } = useSelector(({ user }: RootState) => user);

  const [name, setName] = useState<IForm>({
    value: '',
    valid: false,
    focused: false,
    messageClassName: 'none',
  });
  const [email, setEmail] = useState<IForm>({
    value: '',
    valid: false,
    focused: false,
    messageClassName: 'none',
  });

  const [password, setPassword] = useState<IForm>({
    value: '',
    valid: false,
    focused: false,
    messageClassName: 'none',
    type: 'password',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (
      !name.valid ||
      !email.valid ||
      !password.valid ||
      name.value === '' ||
      email.value === '' ||
      password.value === ''
    ) {
      toast.error('Invalid Form Submission!');
    } else {
      const fullname = name.value.split(' ');

      await dispatch(
        createUser(
          { firstName: fullname[0], lastName: fullname[fullname.length - 1] },
          email.value,
          password.value
        )
      );

      if (onClose) onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} className="CreateUser -head">
      <div className="CreateUser -body">
        <div className="CreateUser -close">
          <Close onClick={onClose} />
        </div>

        <h2 className="CreateUser -title">Create User</h2>
        <small>Create a user/admin on the bodds website</small>

        <form className="CreateUser -form" onSubmit={onSubmit}>
          <Input
            label="Full Name"
            attributes={{
              type: 'text',
              name: 'name',
              required: true,
              theme: 'default',
              value: name.value,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange: (event: any) =>
                setName({
                  ...name,
                  value: event.target.value,
                  valid: checkValidity(event.target.value, {
                    required: true,
                    isFullName: true,
                  }),
                  messageClassName:
                    !checkValidity(event.target.value, {
                      required: true,
                      isFullName: true,
                    }) && event.target.value !== ''
                      ? 'input__message--error'
                      : 'none',
                }),
              onFocus: () =>
                setName({
                  ...name,
                  focused: true,
                }),
              onBlur: () =>
                setName({
                  ...name,
                  focused: false,
                }),
            }}
            hasError={!name.valid}
            focused={name.focused}
            message={
              !name.valid && !name.focused && name.value !== '' ? 'Your full name is required!' : ''
            }
            messageClassName={name.messageClassName}
          />

          <Input
            label="Your Email"
            attributes={{
              type: 'email',
              name: 'email',
              placeholder: 'you@example.com',
              required: true,
              theme: 'default',
              value: email.value,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange: (event: any) =>
                setEmail({
                  ...email,
                  value: event.target.value,
                  valid: checkValidity(event.target.value, {
                    required: true,
                    isEmail: true,
                  }),
                  messageClassName:
                    !checkValidity(event.target.value, {
                      required: true,
                      isEmail: true,
                    }) && event.target.value !== ''
                      ? 'input__message--error'
                      : 'none',
                }),
              onFocus: () =>
                setEmail({
                  ...email,
                  focused: true,
                }),
              onBlur: () =>
                setEmail({
                  ...email,
                  focused: false,
                }),
            }}
            hasError={!email.valid}
            focused={email.focused}
            message={
              !email.valid && !email.focused && email.value !== ''
                ? 'Your email is required and must be valid'
                : ''
            }
            messageClassName={email.messageClassName}
          />

          <Input
            label="Password"
            attributes={{
              type: password.type,
              name: 'password',
              required: true,
              theme: 'default',
              value: password.value,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange: (event: any) =>
                setPassword({
                  ...password,
                  value: event.target.value,
                  valid: checkValidity(event.target.value, {
                    required: true,
                    minLength: 6,
                  }),
                  messageClassName:
                    !checkValidity(event.target.value, {
                      required: true,
                      minLength: 6,
                    }) && event.target.value !== ''
                      ? 'input__message--error'
                      : 'none',
                }),
              onFocus: () =>
                setPassword({
                  ...password,
                  focused: true,
                }),
              onBlur: () =>
                setPassword({
                  ...password,
                  focused: false,
                }),
            }}
            hasError={!password.valid}
            focused={password.focused}
            appendItem="password"
            isPasswordVisible={password.type === 'text' ? true : false}
            handleAppendItemClick={() =>
              setPassword({ ...password, type: password.type === 'text' ? 'password' : 'text' })
            }
            message={
              !password.valid && !password.focused && password.value !== ''
                ? 'Your password is required and must be at least 6 characters long'
                : ''
            }
            messageClassName={password.messageClassName}
          />
          <div className="CreateUser -form-btn">
            <Button className="Button-brand">{loading ? 'Creating User...' : 'Create User'}</Button>

            <Button type="button" className="Button-cancel" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateUser;
