import React from 'react';

interface DeleteProps {
  onClick?: () => void;
}

export const Delete = ({ onClick }: DeleteProps): JSX.Element => {
  return (
    <svg
      id="Layer_3"
      height="512"
      viewBox="0 0 64 64"
      width="512"
      data-name="Layer 3"
      onClick={onClick}>
      <g fill="#f4b2b0">
        <path d="m14 6h36a3 3 0 0 1 3 3v3a0 0 0 0 1 0 0h-42a0 0 0 0 1 0 0v-3a3 3 0 0 1 3-3z" />
        <path d="m29.706 18.823a4.851 4.851 0 0 0 -5.882-3.529l-2.245.561a4.856 4.856 0 0 1 -1.179.145h-1.4a4.852 4.852 0 0 0 -4.339 2.681l-.661 1.319h16z" />
        <path d="m40.8 19.6a6.517 6.517 0 0 0 -9.443-2.508l-1.814 1.23a4.926 4.926 0 0 1 .162.5l.295 1.178h11z" />
        <path d="m48.676 17.794a5.755 5.755 0 0 0 -8.126-1.828l-1.707 1.326a6.49 6.49 0 0 1 1.957 2.308l.2.4h9z" />
      </g>
      <path
        d="m52 19h-1.434l-1.032-1.721a6.753 6.753 0 0 0 -9.534-2.144l-1.3.865a7.493 7.493 0 0 0 -7.9.266l-.885.589a5.827 5.827 0 0 0 -6.338-2.528l-2.243.561a3.9 3.9 0 0 1 -.934.112h-1.4a5.818 5.818 0 0 0 -5.233 3.234l-.385.766h-1.382a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h1.145l5.867 37.156a1 1 0 0 0 .988.844h24a1 1 0 0 0 .988-.844l5.867-37.156h1.145a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1zm-10.9-2.2a4.732 4.732 0 0 1 2.637-.8 4.78 4.78 0 0 1 4.077 2.309l.415.691h-6.629a7.551 7.551 0 0 0 -1.237-1.709zm-9.192 1.128a5.5 5.5 0 0 1 7.302 1.072h-8.429l-.068-.273zm-12.908-.928h1.4a5.878 5.878 0 0 0 1.419-.175l2.244-.561a3.846 3.846 0 0 1 4.652 2.736h-13.085a3.821 3.821 0 0 1 3.37-2zm-1.714 18.128 3.586 3.586-3.054 3.054-.978-6.194zm28.586 8.586-3.586 3.586-3.586-3.586 3.586-3.586zm-2.172-5 3.52-3.52-.96 6.081zm-16.414 3.586-3.586-3.586 3.586-3.585 3.585 3.585zm5-2.171 3.586 3.585-3.586 3.586-3.586-3.586zm-6.415 3.585-3.585 3.586-3.586-3.586 3.586-3.585zm1.415 1.415 3.585 3.585-3.585 3.586-3.586-3.586zm5 5 3.586 3.586-3.286 3.285h-.6l-3.286-3.286zm1.414-1.414 3.586-3.587 3.586 3.586-3.587 3.586zm3.586-6.415-3.586-3.586 3.586-3.585 3.586 3.585zm-5-5-3.586-3.585 3.586-3.586 3.585 3.585zm-5-5-3.586-3.585 3.586-3.586 3.586 3.586zm-1.414 1.414-3.586 3.586-3.586-3.586 3.586-3.585zm-5 15-1.69 1.69-.634-4.013zm-1.3 4.133 2.719-2.719 3.586 3.586-3.291 3.286h-2.363zm7.719 2.281 1.866 1.872h-3.743zm10 0 1.866 1.872h-3.743zm4.694 1.872-3.285-3.285 3.586-3.587 2.225 2.226-.734 4.646zm1.715-8.286 1.645-1.645-.445 2.842zm-1.414-11.414-3.586-3.586 3.585-3.585 3.586 3.586zm-5-5-3.586-3.585 3.586-3.586 3.585 3.586zm2.7-7.3h4.6l-2.3 2.3zm-7.7 2.3-2.3-2.3h4.6zm-10 0-2.3-2.3h4.6zm-5.129-2.3 3.715 3.715-3.586 3.585-1.008-1.007-.1.1-1.008-6.393zm3.7 36-.315-2h22.919l-.315 2zm26.9-29.164-.465.465-3.592-3.586 3.714-3.715h1.416zm3.243-8.836h-38v-2h38z"
        fill="#b3404a"
      />
      <path
        d="m50 5h-11a4 4 0 0 0 -4-4h-6a4 4 0 0 0 -4 4h-11a4 4 0 0 0 -4 4v3a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1v-3a4 4 0 0 0 -4-4zm-21-2h6a2 2 0 0 1 2 2h-10a2 2 0 0 1 2-2zm23 8h-40v-2a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2z"
        fill="#b3404a"
      />
    </svg>
  );
};

export default Delete;
