import axios from 'axios';

import { signature, appKey, baseUrl } from 'Shared/config';

const token = window.localStorage.getItem('token');

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    signature,
    appKey,
    authorization: `Bearer ${token}`,
  },
});

export default instance;
