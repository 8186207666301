import React from 'react';

import './_modal.scss';

type modalWidth = 'small' | 'medium' | 'large';

interface ModalProps {
  isOpen: boolean;
  width: modalWidth;
  children: JSX.Element;
  onClose?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export const Modal = ({
  isOpen,
  onClose,
  width,
  children,
  className,
  style,
}: ModalProps): JSX.Element => {
  return (
    <div className={`Modal -head ${isOpen ? '-open' : '-closed'}`}>
      <div className="Modal -background" onClick={onClose}>
        <div className={`Modal -body -${width} ${className}`} style={style}>
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  width: 'medium',
};

export default Modal;
