import * as types from 'Store/types/user';
import { IAction, IUser } from 'Types/general';

export interface IinitialState {
  loading: boolean;
  users: IUser[];
}

const initialState: IinitialState = {
  loading: false,
  users: [],
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const userInit = (state: IinitialState, action: IAction) => {
  return { ...state, loading: true };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const userFail = (state: IinitialState, action: IAction) => {
  return { ...state, loading: false };
};

const fetchUsers = (state: IinitialState, action: IAction) => {
  return { ...state, users: action.payload, loading: false };
};

const createUser = (state: IinitialState, action: IAction) => {
  return { ...state, users: [action.payload, ...state.users], loading: false };
};

const reducer = (state = initialState, action: IAction): IinitialState => {
  switch (action.type) {
    case types.USER_INIT:
      return userInit(state, action);
    case types.USER_FAIL:
      return userFail(state, action);
    case types.FETCH_USERS:
      return fetchUsers(state, action);
    case types.CREATE_USER:
      return createUser(state, action);
    default:
      return state;
  }
};

export default reducer;
