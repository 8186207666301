import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { login as loginAction, removeAlert } from 'Store/actions';
import { RootState } from 'Store';
import { checkValidity } from 'Shared/validations';
import { IForm } from 'Types/general';

import Input from 'Components/Input';
import Button from 'Components/Button';
import BoddsLogo from 'Assets/images/bodds-logo.png';

import './_login.scss';

export const Login = (): JSX.Element => {
  const dispatch = useDispatch();
  const { loading } = useSelector(({ auth }: RootState) => auth);
  const { type, message } = useSelector(({ alert }: RootState) => alert);

  const [email, setEmail] = useState<IForm>({
    value: '',
    valid: false,
    focused: false,
    messageClassName: 'none',
  });

  const [password, setPassword] = useState<IForm>({
    value: '',
    valid: false,
    focused: false,
    messageClassName: 'none',
    type: 'password',
  });

  useEffect(() => {
    dispatch(removeAlert());
    if (type) toast[type](message);
  }, [type]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!email.valid || !password.valid || email.value === '' || password.value === '') {
      toast.error('Invalid Form Submission!');
    } else {
      await dispatch(loginAction(email.value, password.value));
    }
  };
  return (
    <div className="Login -head">
      <ToastContainer />
      <div className="Login -logo">
        <img src={BoddsLogo} alt="Bodds Logo" />
      </div>
      <div className="Login -body">
        <div className="Login -form">
          <h2>Authenticate</h2>

          <form onSubmit={onSubmit}>
            <Input
              label="Your Email"
              attributes={{
                type: 'email',
                name: 'email',
                placeholder: 'you@example.com',
                required: true,
                theme: 'default',
                value: email.value,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange: (event: any) =>
                  setEmail({
                    ...email,
                    value: event.target.value,
                    valid: checkValidity(event.target.value, {
                      required: true,
                      isEmail: true,
                    }),
                    messageClassName:
                      !checkValidity(event.target.value, {
                        required: true,
                        isEmail: true,
                      }) && event.target.value !== ''
                        ? 'input__message--error'
                        : 'none',
                  }),
                onFocus: () =>
                  setEmail({
                    ...email,
                    focused: true,
                  }),
                onBlur: () =>
                  setEmail({
                    ...email,
                    focused: false,
                  }),
              }}
              hasError={!email.valid}
              focused={email.focused}
              message={
                !email.valid && !email.focused && email.value !== ''
                  ? 'Your email is required and must be valid'
                  : ''
              }
              messageClassName={email.messageClassName}
            />

            <Input
              label="Password"
              attributes={{
                type: password.type,
                name: 'password',
                required: true,
                theme: 'default',
                value: password.value,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange: (event: any) =>
                  setPassword({
                    ...password,
                    value: event.target.value,
                    valid: checkValidity(event.target.value, {
                      required: true,
                      minLength: 6,
                    }),
                    messageClassName:
                      !checkValidity(event.target.value, {
                        required: true,
                        minLength: 6,
                      }) && event.target.value !== ''
                        ? 'input__message--error'
                        : 'none',
                  }),
                onFocus: () =>
                  setPassword({
                    ...password,
                    focused: true,
                  }),
                onBlur: () =>
                  setPassword({
                    ...password,
                    focused: false,
                  }),
              }}
              hasError={!password.valid}
              focused={password.focused}
              appendItem="password"
              isPasswordVisible={password.type === 'text' ? true : false}
              handleAppendItemClick={() =>
                setPassword({ ...password, type: password.type === 'text' ? 'password' : 'text' })
              }
              message={
                !password.valid && !password.focused && password.value !== ''
                  ? 'Your password is required and must be valid'
                  : ''
              }
              messageClassName={password.messageClassName}
            />

            <div className="Login -btn">
              <Button className="Button-brand">{loading ? 'Loading...' : 'Gain Access'}</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
