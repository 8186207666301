import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { logout } from 'Store/actions';
import { RootState } from 'Store';
import * as urls from 'Shared/routes.json';

//import HomeIcon from 'Components/Icons/Dashboard/Home';
import UserIcon from 'Components/Icons/Dashboard/User';
import ProjectIcon from 'Components/Icons/Dashboard/Project';
import LogoutIcon from 'Components/Icons/Dashboard/Logout';
import BoddsLogo from 'Assets/images/bodds-logo.png';

import './_dashboard.scss';
import 'react-toastify/dist/ReactToastify.css';

interface DashboardProps {
  children: JSX.Element;
}

export const Dashboard = ({ children }: DashboardProps): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }: RootState) => auth);

  const onLogout = () => {
    const confirm = window.confirm('Logging out... Click ok to continue');
    if (confirm) dispatch(logout());
  };

  return (
    <div className="Dashboard -head">
      <div className="Dashboard -header">
        <NavLink to={urls.Home}>
          <img src={BoddsLogo} alt="Bodds Logo" />
        </NavLink>
      </div>
      <div className="Dashboard -nav">
        <div className="Dashboard -nav-group">
          {/* <NavLink
            to={urls.Home}
            className="Dashboard -nav-item"
            activeClassName="Dashboard -nav-item--active">
            <HomeIcon color={urls.Home === location.pathname ? '#2294e3' : 'black'} />
            Home
          </NavLink> */}

          <NavLink
            to={urls.Projects}
            className="Dashboard -nav-item"
            activeClassName="Dashboard -nav-item--active">
            <ProjectIcon color={urls.Projects === location.pathname ? '#2294e3' : 'black'} />
            Projects
          </NavLink>

          {user?.userType === 'Admin' && (
            <NavLink
              to={urls.Users}
              className="Dashboard -nav-item"
              activeClassName="Dashboard -nav-item--active">
              <UserIcon color={urls.Users === location.pathname ? '#2294e3' : 'black'} />
              Users
            </NavLink>
          )}
        </div>

        <div className="Dashboard -nav-group -logout">
          <div className="Dashboard -nav-item" onClick={() => onLogout()}>
            <LogoutIcon />
            Logout
          </div>
        </div>
      </div>
      <div className="Dashboard -body">{children}</div>
    </div>
  );
};

export default Dashboard;
