import * as types from 'Store/types/auth';
import { IAction, IUser } from 'Types/general';

export interface IinitialState {
  loading: boolean;
  isAuthenticated: boolean;
  user: IUser | null;
  token: string;
}

const initialState: IinitialState = {
  loading: false,
  isAuthenticated: false,
  user: null,
  token: '',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authInit = (state: IinitialState, action: IAction) => {
  return { ...state, loading: true };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authFail = (state: IinitialState, action: IAction) => {
  return { ...state, loading: false };
};

const login = (state: IinitialState, action: IAction) => {
  return {
    ...state,
    loading: false,
    isAuthenticated: true,
    user: action.payload.user,
    token: action.payload.token,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logout = (state: IinitialState, action: IAction) => {
  return { ...state, loading: false, isAuthenticated: false, user: null, token: '' };
};

const reducer = (state = initialState, action: IAction): IinitialState => {
  switch (action.type) {
    case types.AUTH_INIT:
      return authInit(state, action);
    case types.AUTH_FAIL:
      return authFail(state, action);
    case types.LOGIN:
      return login(state, action);
    case types.LOGOUT:
      return logout(state, action);
    default:
      return state;
  }
};

export default reducer;
