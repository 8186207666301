import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { fetchUsers, removeAlert, updateUser, deleteUser } from 'Store/actions';
import { RootState } from 'Store';

import Dashboard from 'Layouts/Dashboard';
import Button from 'Components/Button';
import CreateUser from 'Components/Modals/CreateUser';
import UserIcon from 'Components/Icons/Dashboard/User';

import './_users.scss';

export const Users = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isAddUserOpen, toggleAddUser] = useState<boolean>(false);
  const { loading, users } = useSelector(({ user }: RootState) => user);
  const { type, message } = useSelector(({ alert }: RootState) => alert);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(removeAlert());
    if (type) toast[type](message);
  }, [type]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const onUpdateUserType = async (userId: string, userType: string) => {
    const confirm = window.confirm(`Changing User type to ${userType}... Click ok to continue`);
    if (confirm) await dispatch(updateUser(userId, userType));
  };

  const onDeleteUser = async (userId: string) => {
    const confirm = window.confirm('Deleting user... Click ok to continue');
    if (confirm) await dispatch(deleteUser(userId));
  };

  return (
    <Dashboard>
      <>
        <ToastContainer />
        <div className="Users -head">
          <div className="Users -header">
            <h2>Bodds Admin Users</h2>
            <Button
              type="button"
              className="Button-brand"
              onClick={() => toggleAddUser(!isAddUserOpen)}>
              Add User
            </Button>
          </div>
          <div className="Users -body">
            {users && !loading && users.length === 0 && (
              <div className="Users -empty">
                <UserIcon />
                There are no users to display
              </div>
            )}

            {users && !loading && users.length > 0 && (
              <div className="Users -block">
                {users.map((user, idx: number) => (
                  <div key={idx} className="Users -block-item">
                    <div className="Users -block-item-name">
                      {user.name.firstName + user.name.lastName}({user.email})
                    </div>
                    <div
                      className="Users -block-item-type"
                      onClick={() =>
                        onUpdateUserType(user._id, user.userType === 'User' ? 'Admin' : 'User')
                      }>
                      {user.userType}
                    </div>
                    <div className="Users -block-item-controls">
                      <Button
                        type="button"
                        className="Button-brand"
                        onClick={() => onDeleteUser(user._id)}>
                        Delete User
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <CreateUser isOpen={isAddUserOpen} onClose={() => toggleAddUser(!isAddUserOpen)} />
      </>
    </Dashboard>
  );
};

export default Users;
