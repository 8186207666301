import React from 'react';

interface LogoutProps {
  color: string;
}

export const Logout = ({ color }: LogoutProps): JSX.Element => {
  return (
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none">
      <g clipPath="url(#clip4)">
        <path
          d="M21.2826 12.7826H7.41295C7.01695 12.7826 6.69556 12.432 6.69556 12C6.69556 11.568 7.01695 11.2174 7.41295 11.2174H21.2826C21.6786 11.2174 22 11.568 22 12C22 12.432 21.6786 12.7826 21.2826 12.7826Z"
          fill={color}
        />
        <path
          d="M11.2392 16.9565C11.0554 16.9565 10.8719 16.8804 10.7321 16.727L6.9061 12.5532C6.62587 12.2473 6.62587 11.7516 6.9061 11.4459L10.7321 7.27207C11.0124 6.96636 11.4667 6.96636 11.7471 7.27207C12.0273 7.57777 12.0273 8.0734 11.7471 8.37911L8.42792 12L11.7471 15.6209C12.0273 15.9266 12.0273 16.4223 11.7471 16.728C11.6064 16.8804 11.4227 16.9565 11.2392 16.9565Z"
          fill={color}
        />
        <path
          d="M10.5218 23.4783C4.72049 23.4783 0 18.3287 0 12C0 5.67129 4.72049 0.521667 10.5218 0.521667C14.8748 0.521667 18.7182 3.37869 20.3146 7.80111C20.46 8.20273 20.2782 8.65671 19.9101 8.81625C19.5417 8.97292 19.1256 8.77765 18.9793 8.37393C17.601 4.5547 14.2809 2.08688 10.5218 2.08688C5.51144 2.08688 1.43478 6.53434 1.43478 12C1.43478 17.4656 5.51144 21.9131 10.5218 21.9131C14.2809 21.9131 17.601 19.4453 18.9793 15.6272C19.1247 15.2233 19.5409 15.0282 19.9101 15.1847C20.2782 15.3433 20.46 15.7982 20.3146 16.2C18.7182 20.6213 14.8748 23.4783 10.5218 23.4783Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip4">
          <rect width="22" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Logout.defaultProps = {
  color: '#761717',
};

export default Logout;
