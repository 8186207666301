import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'Store';
import * as urls from 'Shared/routes.json';

//pages
import Login from 'Pages/Login';
import Projects from 'Pages/Projects';
import Project from 'Pages/Project';
import Users from 'Pages//Users';

function App(): JSX.Element {
  const { isAuthenticated, user } = useSelector(({ auth }: RootState) => auth);

  return (
    <>
      {isAuthenticated ? (
        <Switch>
          <Route path={urls.Projects} component={Projects} />
          <Route path={urls.Project} component={Project} />
          {user?.userType === 'Admin' && <Route path={urls.Users} component={Users} />}
          <Redirect to={urls.Projects} />
        </Switch>
      ) : (
        <Switch>
          <Route path={urls.Root} exact component={Login} />
          <Redirect to={urls.Root} />
        </Switch>
      )}
    </>
  );
}

export default App;
