import * as types from 'Store/types/alert';
import { toastifyTypes, IAction } from 'Types/general';
import { RootState } from 'Store';

export const addAlert =
  (type: toastifyTypes, message: string) =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<void> => {
    dispatch({ type: types.ALERT_ADD, payload: { type, message } });
  };

export const removeAlert =
  () =>
  async (
    dispatch: (e: IAction) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ): Promise<void> => {
    dispatch({ type: types.ALERT_REMOVE });
  };
