import React from 'react';

interface UserProps {
  color: string;
}

export const User = ({ color }: UserProps): JSX.Element => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M11 14.1829C14.2159 14.1829 16.8231 11.008 16.8231 7.0915C16.8231 3.17492 15.9671 0 11 0C6.0328 0 5.17664 3.17492 5.17664 7.0915C5.17664 11.008 7.78389 14.1829 11 14.1829Z"
        fill={color}
      />
      <path
        d="M0.00114511 25.0107C0.000153416 24.7719 -0.000838282 24.9434 0.00114511 25.0107V25.0107Z"
        fill={color}
      />
      <path
        d="M21.9984 25.1972C22.0016 25.1319 21.9995 24.7437 21.9984 25.1972V25.1972Z"
        fill={color}
      />
      <path
        d="M21.9859 24.7243C21.8781 17.9017 20.9893 15.9576 14.1885 14.727C14.1885 14.727 13.2312 15.95 10.9999 15.95C8.76856 15.95 7.81107 14.727 7.81107 14.727C1.08447 15.9442 0.141693 17.8594 0.0177312 24.5026C0.00756625 25.0451 0.00285571 25.0736 0.0010376 25.0106C0.00145081 25.1286 0.00194647 25.3469 0.00194647 25.7274C0.00194647 25.7274 1.62106 29 10.9999 29C20.3785 29 21.9978 25.7274 21.9978 25.7274C21.9978 25.4829 21.998 25.3129 21.9982 25.1972C21.9964 25.2362 21.9928 25.1607 21.9859 24.7243Z"
        fill={color}
      />
    </svg>
  );
};

User.defaultProps = {
  color: 'black',
};

export default User;
