import React from 'react';

import Close from 'Components/Icons/General/Close';

import './_expandImg.scss';

interface ExpandImgProps {
  isOpen: boolean;
  onClose: () => void;
  image: string;
}

export const ExpandImg = ({ isOpen, onClose, image }: ExpandImgProps): JSX.Element => {
  return (
    <div className={`ExpandImg -head ${isOpen ? '-open' : '-closed'}`}>
      <div className="ExpandImg -background" onClick={onClose}></div>
      <div className="ExpandImg -img">
        <Close color="white" onClick={onClose} />
        <img src={image} alt="expanded image" />
      </div>
    </div>
  );
};

export default ExpandImg;
