import * as types from 'Store/types/project';
import { IAction, IProject } from 'Types/general';

export interface IinitialState {
  loading: boolean;
  projects: IProject[];
  selectedProject: IProject | null;
}

const initialState: IinitialState = {
  loading: false,
  projects: [],
  selectedProject: null,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const projectInit = (state: IinitialState, action: IAction) => {
  return { ...state, loading: true };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const projectFail = (state: IinitialState, action: IAction) => {
  return { ...state, loading: false };
};

const fetchProjects = (state: IinitialState, action: IAction) => {
  return { ...state, projects: action.payload, loading: false };
};

const createProject = (state: IinitialState, action: IAction) => {
  return { ...state, projects: [action.payload, ...state.projects], loading: false };
};

const selectProject = (state: IinitialState, action: IAction) => {
  return { ...state, selectedProject: action.payload, loading: false };
};

const editProject = (state: IinitialState, action: IAction) => {
  return {
    ...state,
    selectedProject: state.projects.find(project => project._id === action.payload._id) || null,
    loading: false,
  };
};

const deleteGallery = (state: IinitialState, action: IAction) => {
  return {
    ...state,
    selectedProject: state.projects.find(project => project._id === action.payload) || null,
    loading: false,
  };
};

const reducer = (state = initialState, action: IAction): IinitialState => {
  switch (action.type) {
    case types.PROJECT_INIT:
      return projectInit(state, action);
    case types.PROJECT_FAIL:
      return projectFail(state, action);
    case types.FETCH_PROJECTS:
      return fetchProjects(state, action);
    case types.CREATE_PROJECT:
      return createProject(state, action);
    case types.EDIT_PROJECT:
      return editProject(state, action);
    case types.SELECT_PROJECT:
      return selectProject(state, action);
    case types.DELETE_GALLERY:
      return deleteGallery(state, action);
    default:
      return state;
  }
};

export default reducer;
