import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { deleteProject, deleteGallery, removeAlert } from 'Store/actions';
import { RootState } from 'Store';
import * as urls from 'Shared/routes.json';
import { baseImageUrl } from 'Shared/config';

import Dashboard from 'Layouts/Dashboard';
import Button from 'Components/Button';
import EditProject from 'Components/Modals/EditProject';
import ExpandImg from 'Components/ExpandImg';
import DeleteIcon from 'Components/Icons/General/Delete';

import './_project.scss';

export const Project = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEditProjectOpen, toggleEditProject] = useState<boolean>(false);
  const [expandedImgOpen, toggleExpandedImg] = useState<boolean>(false);
  const [imgToExpand, setImageToExpand] = useState<string>('');
  const { selectedProject } = useSelector(({ project }: RootState) => project);
  const { type, message } = useSelector(({ alert }: RootState) => alert);

  useEffect(() => {
    dispatch(removeAlert());
    if (type) toast[type](message);
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDeleteProject = async (projectId: string) => {
    const confirm = window.confirm('Deleting Project... Click ok to continue');
    if (confirm) {
      await dispatch(deleteProject(projectId));
      history.push(urls.Projects);
    }
  };

  const onDeleteGallery = async (projectId: string, galleryId: string) => {
    const confirm = window.confirm('Deleting Gallery Image... Click ok to continue');
    if (confirm) {
      await dispatch(deleteGallery(projectId, galleryId));
    }
  };

  return (
    <Dashboard>
      <>
        <ToastContainer />
        <div className="Project -head">
          <div className="Project -header">
            <img
              src={
                selectedProject && selectedProject.gallery.length > 0
                  ? baseImageUrl + selectedProject.gallery[0].image
                  : ''
              }
              alt=""
            />
          </div>

          <div className="Project -controls">
            <Button
              type="button"
              className="Button-brand"
              onClick={() => toggleEditProject(!isEditProjectOpen)}>
              Edit Project
            </Button>
            <Button
              type="button"
              className="Button-brand Project -controls-delete"
              onClick={() => onDeleteProject(selectedProject ? selectedProject._id : '')}>
              Delete Project
            </Button>
          </div>

          <div className="Project -content">
            {selectedProject && (
              <>
                <div className="Project -content-item">
                  <h2>
                    Case Study
                    <br />
                    Background
                  </h2>
                  <div
                    className="Project -content-text"
                    dangerouslySetInnerHTML={{
                      __html: selectedProject.background,
                    }}></div>
                </div>

                <div className="Project -content-item">
                  <h2>
                    Our
                    <br />
                    Solution
                  </h2>
                  <div
                    className="Project -content-text"
                    dangerouslySetInnerHTML={{
                      __html: selectedProject.solutions,
                    }}></div>
                </div>

                <div className="Project -content-item">
                  <h2>Gallery</h2>
                  <div className="Project -content-gallery">
                    {selectedProject.gallery.length > 0 &&
                      selectedProject.gallery.map((item, idx: number) => (
                        <div key={idx} className="Project -content-gallery-item">
                          <img
                            src={baseImageUrl + item.image}
                            onClick={() => {
                              setImageToExpand(baseImageUrl + item.image);
                              toggleExpandedImg(!expandedImgOpen);
                            }}
                            alt=""
                          />
                          <DeleteIcon
                            onClick={() => onDeleteGallery(selectedProject._id, item._id)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <EditProject
          isOpen={isEditProjectOpen}
          onClose={() => toggleEditProject(!isEditProjectOpen)}
        />

        <ExpandImg
          isOpen={expandedImgOpen}
          onClose={() => toggleExpandedImg(!expandedImgOpen)}
          image={imgToExpand}
        />
      </>
    </Dashboard>
  );
};

export default Project;
